<span
   (click)="sortMe()"
   class="sortColumn"
   [ngClass]="{
      'active-sortColumn': sortState === 'descending' || sortState === 'ascending',
      'ascending': sortState === 'ascending',
      'descending': sortState === 'descending',
   }"
   data-test-id="sortColumn"
>
   @if (sortState === "disordered") {
      <lim-ui-icon iconSize="small" icon="sort" />
   }
   @if (sortState === "descending") {
      <lim-ui-icon iconSize="small" icon="sortDesc" />
   }
   @if (sortState === "ascending") {
      <lim-ui-icon iconSize="small" icon="sortAsc" />
   }
   @if (name) {
      <span [limbleHtml]="name"></span>
   }
   @if (iconName) {
      <lim-ui-icon [icon]="iconName" />
   }
</span>
