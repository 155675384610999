import { Injectable, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class MobileHeaderTitleService {
   public readonly title = signal<string>("");

   public setTitle(newTitle: string): void {
      this.title.set(newTitle);
   }
}
