import { inject, Injectable } from "@angular/core";
import { CredService } from "src/app/users/services/creds/cred.service";

@Injectable({ providedIn: "root" })
export class UserTypeService {
   private readonly credService = inject(CredService);

   public get(): string {
      if (this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)) {
         return "superUser";
      } else if (
         this.credService.checkCredAnywhere(this.credService.Permissions.AddUsers)
      ) {
         return "managerUser";
      }
      return "techUser";
   }
}
