import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   IconComponent,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "confirm",
   templateUrl: "./confirm.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      IconComponent,
      TextButtonComponent,
   ],
})
/**
 * @deprecated Use `ConfirmModalComponent` from `src/app/lite/ui/confirm-modal/confirm-modal.component.ts` instead.
 */
export class Confirm implements OnInit {
   public message;
   public title;
   public confirm;
   public cancel;
   public resolve;
   public modalInstance;
   public learnMoreLink;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected openLearnMoreLink() {
      window.open(this.learnMoreLink, "_blank");
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.learnMoreLink = this.resolve.learnMoreLink;
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.confirm = this.resolve.confirm ?? this.lang().Yes;
      this.cancel = this.resolve.cancel ?? this.lang().No;
   }

   close = () => {
      if (this.modalInstance) {
         this.modalInstance.close(0);
      }
   };

   submit = () => {
      if (this.modalInstance) {
         this.modalInstance.close(1);
      }
   };
}
