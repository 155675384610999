import { DOCUMENT } from "@angular/common";
import { inject, Injectable } from "@angular/core";
import { isNativeMobileApp } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import { PlanTypeService } from "src/app/shared/external-scripts/util/plan-type.service";
import { UserTypeService } from "src/app/shared/external-scripts/util/user-type.service";
import { MobileNativeDeviceInfoService } from "src/app/shared/services/mobile-native-device-info/mobile-native-device-info.service";
import { ManageUser } from "src/app/users/services//manageUser";
import { environment } from "src/environments/environment";

type HeapEnabledWindow = Document["defaultView"] & {
   heap: {
      addEventProperties: (properties: Record<string, number | string | Date>) => void;
      addUserProperties: (properties: Record<string, number | string | Date>) => void;
      identify: (userId: string) => void;
      track: (
         eventName: string,
         eventProperties?: Record<string, number | string | boolean>,
      ) => void;
   };
};

type LicenseType = "minimalUse" | "regular" | "viewOnly";

@Injectable({ providedIn: "root" })
export class HeapService {
   private readonly windowInstance: HeapEnabledWindow;

   private readonly document = inject(DOCUMENT);
   private readonly manageUser = inject(ManageUser);
   private readonly userType = inject(UserTypeService);
   private readonly planType = inject(PlanTypeService);
   private readonly deviceInfoService = inject(MobileNativeDeviceInfoService);

   public constructor() {
      this.windowInstance = this.document.defaultView as HeapEnabledWindow;

      /**
       * This will set up the user data.
       */
      this.manageUser.getData();
   }

   public async load(): Promise<void> {
      if (environment.production !== true) return;
      const heapModule = await import("../../../assets/js/heap");
      heapModule.heapSetup();
      const win = window as any;
      if (window.location.hostname.includes("limblecmms.com")) {
         win.heap.load("1180072101");
      } else if (window.location.hostname.includes("limblestaging.com")) {
         win.heap.load("2914399438");
      }
   }

   public identifyUser(): void {
      if (this.shouldNotRegisterEvent()) {
         return;
      }
      const currentUser = this.manageUser.getCurrentUser();
      if (!currentUser) {
         return;
      }
      const licenseType = this.getLicenseType();
      if (!licenseType) {
         return;
      }

      this.identifyFirstMobileAppLoad();
      this.windowInstance.heap.addUserProperties({
         Name: `${currentUser.userInfo.fName} ${currentUser.userInfo.lName}`,
         userLoginName: currentUser.userInfo.userLoginName,
         userEmail: currentUser.userInfo.userEmail,
         customerID: currentUser.userInfo.customerID,
         userID: currentUser.userInfo.userID,
         customerName: currentUser.userInfo.customerName,
         customerStatus: currentUser.userInfo.customerStatus,
         customerPlan: currentUser.userInfo.customerPlan,
         userType: this.getUserType(licenseType),
         userCreatedDate: new Date(
            currentUser.userInfo.userDateAdded * 1000,
         ).toISOString(),
         accountCreatedDate: new Date(
            currentUser.userInfo.customerAgreementDate * 1000,
         ).toISOString(),
         accountConversionDate: new Date(
            currentUser.userInfo.accountConversionDate * 1000,
         ).toISOString(),
         subscriptionType: currentUser.userInfo.subscriptionType,
         userLicenseType: licenseType,
         leadSource: currentUser.userInfo.leadSource,
      });
      this.windowInstance.heap.addEventProperties({
         customerStatus: currentUser.userInfo.customerStatus,
         customerTier: this.planType.get(),
      });

      this.windowInstance.heap.identify(currentUser.userInfo.userLoginName);
   }

   private getLicenseType(): LicenseType | null {
      const license = this.manageUser.getCurrentUserLicenseType();
      if (!license || license === "unset") {
         return null;
      }

      return license as LicenseType;
   }

   private getUserType(licenseType: LicenseType): string {
      /**
       * If user is a techUser, use the licenseType to determine the user type.
       */
      const type = this.userType.get();
      if (type === "techUser") {
         switch (licenseType) {
            case "minimalUse":
               return "operator";
            default:
               return type;
         }
      }

      return type;
   }

   public trackEvent(
      eventName: string,
      eventProperties?: Record<string, number | string | boolean>,
   ): void {
      if (this.shouldNotRegisterEvent()) {
         return;
      }

      this.windowInstance.heap.track(eventName, eventProperties ?? {});
   }

   public async setAccountProperties(): Promise<void> {
      if (this.shouldNotRegisterEvent()) {
         return;
      }

      const currentUser = this.manageUser.getCurrentUser();
      const featureKeys = Object.entries(currentUser.userInfo)
         .filter(([key]) => key.startsWith("feature"))
         .map(([key, value]) => [key, Boolean(value)]);
      const mappedFeatures = Object.fromEntries(featureKeys);
      const customerProperties = {
         customerID: currentUser.userInfo.customerID,
         customerName: currentUser.userInfo.customerName ?? "unknown",
         customerStatus: currentUser.userInfo.customerStatus ?? "unknown",
         customerPlan: currentUser.userInfo.customerPlan ?? "unknown",
         accountCreatedDate:
            new Date(currentUser.userInfo.customerAgreementDate * 1000).toISOString() ??
            "unknown",
         subscriptionType: currentUser.userInfo.subscriptionType ?? "unknown",
         ...mappedFeatures,
      };

      await axios({
         method: "POST",
         url: "phpscripts/setAccountProperties.php",
         data: customerProperties,
      });
   }

   private shouldNotRegisterEvent(): boolean {
      // skip if heap is not loaded (e.g. blocked by adblocker) or if one of these other conditions is true
      const currentUser = this.manageUser.getCurrentUser();
      return (
         !this.windowInstance.heap ||
         currentUser.userInfo.customerID == 36 ||
         currentUser.userInfo.customerStatus == "internal" ||
         currentUser.userInfo.userInternal == 1 ||
         currentUser.userInfo.remmah
      );
   }

   private async identifyFirstMobileAppLoad(): Promise<void> {
      if (isNativeMobileApp()) {
         const userDeviceInfo = await this.deviceInfoService.deviceInfo;
         const isFirstLaunch = userDeviceInfo.isFirstLaunch;
         if (isFirstLaunch) {
            this.trackEvent("firstMobileAppLoad", userDeviceInfo);
         }
      }
   }
}
