import { NgClass } from "@angular/common";
import type { OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { IconComponent, LimbleHtmlDirective } from "@limblecmms/lim-ui";

@Component({
   selector: "sort-column",
   templateUrl: "./sortColumn.element.component.html",
   styleUrls: ["./sortColumn.element.component.scss"],
   imports: [NgClass, IconComponent, LimbleHtmlDirective],
})
export class SortColumn implements OnChanges, OnInit {
   @Input() public column: string | undefined;
   @Input() public name: string | undefined;
   @Input() public bind: string | undefined;
   @Input() public iconName: Aliases | undefined;
   //The 'bind' parameter is intentionally left as any in order to prevent misalignment
   // between strongly typed sort functions' signatures and the 'string' type
   // eg passing in a function with a parameter of type 'keyof Bill' does not align with
   // the type 'string'
   @Input() public sortFn: ((bind: any) => void) | undefined;
   @Input() public disableSort: boolean = false;

   @Output() public readonly bindChange = new EventEmitter();

   public sortState: "disordered" | "descending" | "ascending" | "disabled" =
      "disordered";

   public ngOnChanges(changes: SimpleChanges) {
      if (changes?.bind?.currentValue !== changes?.bind?.previousValue) {
         if (this.disableSort === true) {
            this.sortState = "disabled";
            return;
         }
         if (
            this.bind === undefined ||
            this.column === undefined ||
            (this.bind !== this.column && this.bind !== `-${this.column}`)
         ) {
            this.sortState = "disordered";
            return;
         }
         if (this.bind.startsWith("-")) {
            this.sortState = "descending";
            return;
         }
         this.sortState = "ascending";
      }
   }

   public ngOnInit() {
      if (this.disableSort === true) {
         this.sortState = "disabled";
      }
   }

   sortMe = () => {
      if (this.column === undefined) {
         console.warn("cannot sort an undefined column");
         return;
      }
      if (
         this.column === "checklistPriorityDisplay" &&
         this.bind !== "checklistPriorityDisplay" &&
         this.bind != "-checklistPriorityDisplay"
      ) {
         this.sortState = "ascending";
      }

      if (this.sortState === "descending") {
         this.sortState = "ascending";
      } else {
         this.sortState = "descending";
      }

      if (this.sortState === "ascending") {
         this.bind = this.column;
         this.bindChange.emit(this.bind);
         if (this.sortFn) {
            this.sortFn(this.bind);
         }
      }

      if (this.sortState === "descending") {
         this.bind = `-${this.column}`;
         this.bindChange.emit(this.bind);
         if (this.sortFn) {
            this.sortFn(this.bind);
         }
      }
   };
}
