import { inject, Injectable } from "@angular/core";
import { ManageUser } from "src/app/users/services//manageUser";

@Injectable({ providedIn: "root" })
export class PlanTypeService {
   private readonly manageUser = inject(ManageUser);

   public get(): string {
      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser.userInfo.customerPlan == "basic") {
         return "free";
      }
      return "paid";
   }
}
