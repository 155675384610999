<lim-ui-modal>
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span [limbleHtml]="message"></span>
         @if (learnMoreLink) {
            <lim-ui-text-button class="link-text" (click)="openLearnMoreLink()">
               {{ lang().LearnMore }}
               <lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small" />
            </lim-ui-text-button>
         }
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-outlined-button (click)="submit()" data-test-id="confirmModalConfirmButton">
         {{ this.confirm }}
      </lim-ui-outlined-button>
      <lim-ui-primary-button (click)="close()" data-test-id="confirmModalCancelButton">
         {{ this.cancel }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
